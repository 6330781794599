<template>
	<div id="page-login" class="flex w-full layout--middle">
		<div class="login-card w-fit mx-auto self-center">
			<vx-card class="shadow-none">
				<div class="h-full mx-auto self-center bg-white bg-dark">
					<div class="iframe-holder">
						<iframe
							src="https://assets.sinao.fr/register/"
							allowfullscreen
							scrolling="no"
							frameborder="0"
						></iframe>
					</div>
				</div>
			</vx-card>
		</div>
	</div>
</template>

<script>
	export default {};
</script>

<style>
	.iframe-holder {
		width: 500px;
		height: 420px;
	}

	@media screen and (max-width: 600px) {
		.login-card {
			width: 100%;
			padding-left: 20px;
			padding-right: 20px;
		}

		.iframe-holder {
			width: 100%;
		}
	}

	.iframe-holder iframe {
		width: 100%;
		height: 100%;
	}
</style>
